import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

const CookieBar = ({ cookieTitle, cookieTxt, setShowModal, setTitleModal, setContentModal }) => {
	const [cookies, setCookie] = useCookies(['acceptedCookies'])
	const [showCookieBar, setShowCookieBar] = useState(false)

	const openModal = (e, title, content) => {
		e.preventDefault()
		setShowModal(true)
		setTitleModal(title)
		setContentModal(content)
	}

	const accept = (e) => {
		e.preventDefault()
		setCookie('acceptedCookies', 'true', { path: '/', expires: new Date(Date.now() + 5.53e+9), sameSite: 'strict' })
		setShowCookieBar(false)
	}

	useEffect(() => {
		if (!cookies.acceptedCookies) {
			setShowCookieBar(true)
		}
	}, [])

	return (
		<div className={`cookie-bar ${showCookieBar ? 'active' : ''}`}>
			<div className="cookie-bar__inner">
				<p className="content">Om je de best mogelijke ervaring te bieden op onze website gebruiken wij <button onClick={e => openModal(e, cookieTitle, cookieTxt)} >cookies</button>.</p>
				<button onClick={e => accept(e)} className="accept">Accepteren</button>
			</div>
		</div>
	)
}

export default CookieBar
