import React, { useRef, useEffect } from 'react'

const Modal = ({show, setShow, title, content}) => {
	const body = document.body
	const html = document.documentElement
	const modal = useRef(null)
	const close = useRef(null)

	if (show) {
		body.classList.add('open-modal')
		html.classList.add('open-modal')
	} else {
		body.classList.remove('open-modal')
		html.classList.remove('open-modal')
	}

	const closeModal = (e) => {
		if (e) e.preventDefault()
		modal.current.classList.add('close-modal')
		setTimeout(() => {
			setShow(false)
			modal.current.classList.remove('close-modal')
			modal.current.scrollTo({ top: 0, behavior: 'instant' })
		}, 250)
	}

	const focusFirstElement = () => {
		close.current.focus()
	}

	useEffect(() => {
		if (show) {
			document.onkeydown = (evt) => {
				if (evt.keyCode == 27) {
					closeModal()
				}
			}
		}
	}, [show])

	return (
		<div ref={modal} role="dialog" className={`modal ${show ? 'active' : ''}`}>
			<button onClick={e => closeModal(e)} className="modal__close-layer"></button>
			<div className="modal__inner">
				<button ref={close} tabIndex="0" onClick={e => closeModal(e)} className="close">X</button>
				{title &&
					<p className='title' dangerouslySetInnerHTML={{__html: title}}></p>
				}
				<p className='content' dangerouslySetInnerHTML={{__html: content}}></p>
			</div>
			<span tabIndex="0" onFocus={e => focusFirstElement(e)}></span>
		</div>
	)
}

export default Modal
