import React from 'react'

import './BachelorEducation.scss'

import BachelorEducationImage from '../../assets/bacheloropleiding-medische-technologie.jpg' 

const BachelorEducation = () => {
	return (
		<section className='bacheloreducation'>
			<div className="bacheloreducation__container">
				<div className="bacheloreducation__container--image">
					<figure>
						<img src={BachelorEducationImage} alt="Bacheloropleiding &lsquo;Medische technologie&rsquo;" />
						<figcaption>Nieuw</figcaption>
					</figure>
				</div>
				<h2 className="bacheloreducation__container--title line-title"><span>Bacheloropleiding</span><br /> <span>&apos;medische</span><br /> <span>technologie&apos;</span></h2>
				<h3 className="bacheloreducation__container--subtitle">Een mooi vervolg op je diploma dentaaltechnieken</h3>
				<p className="bacheloreducation__container--content">Wil je na je studie dentaaltechnieken verder studeren? Dan is de Bacheloropleiding &lsquo;Medische technologie&rsquo; misschien wel iets voor jou!</p>
				<p className="bacheloreducation__container--content"><strong>Heb je interesse in technologische innovaties? Wil je bijdragen aan een verbetering van de levenskwaliteit voor iedereen?</strong> Als medisch technoloog ben jij de cruciale schakel die zorgexpertise en technologie samenbrengt. En dat is broodnodig. Overal, ook in tandtechnische en digitale labo&rsquo;s.</p>
				<p className="bacheloreducation__container--content"><strong>Wil je meer weten over deze opleiding?</strong><br /> De opleiding wordt georganiseerd door twee hogescholen: <a target="_blank" rel="noreferrer" href="https://www.hogent.be/opleidingen/bachelors/medische-technologie/">HOGENT</a> en <a target="_blank" rel="noreferrer" href="https://www.arteveldehogeschool.be/nl/opleidingen/bachelor/medische-technologie">Arteveldehogeschool</a> Gent. Neem zeker een kijkje op hun websites.</p>
			</div>
		</section>
	)
}

export default BachelorEducation
